import './bootstrap'

import '../../css/app.css'

import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import dayjsLocaleData from 'dayjs/plugin/localeData';
import dayjsRelativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(dayjsDuration);
dayjs.extend(dayjsLocaleData);
dayjs.extend(dayjsRelativeTime);

// window.dayjs = dayjs;

import { createApp, h, DefineComponent } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../../vendor/tightenco/ziggy/dist/vue.m'
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import * as Sentry from "@sentry/vue"

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
        const isProduction = import.meta.env.PROD;

        if (isProduction) {
            Sentry.init({
                app,
                dsn: "https://ba9b3bdee35b470199fb85c1e5c640dd@o79684.ingest.sentry.io/6571413",
                environment: isProduction ? "Production" : "Development",
                integrations: [
                    Sentry.browserTracingIntegration(),
                ],
                tracesSampleRate: isProduction ? 0.05 : 0,
                tracePropagationTargets: ["localhost", "dashboard.pushey.com", "dashboard.pushey.test", /^\//],
                logErrors: true,
            });
        }

        app
            .use(plugin)
            .use(VueGoogleMaps, {
                load: {
                    key: 'AIzaSyBS46tOG5kOoQrvR9THUTGKkfA4dtrpyDM',
                    libraries: 'places',
                },
            })
            .use(ZiggyVue, Ziggy)
            .component(VueQrcode.name!, VueQrcode)
            .mount(el);

        return app;
    },
    progress: {
        color: '#4B5563',
    },
});
